
@import url('https://fonts.googleapis.com/css2?family=Modak&family=Poppins:wght@100;200;300;400;500;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


body{
  font-family: 'Poppins', sans-serif;
}